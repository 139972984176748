import React, { useContext, useEffect, useState, useCallback } from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { MasterDetailTemplateData } from "devextreme/ui/data_grid";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  GetAllRolesDTO,
  GetAllSitesInfoDTO,
  GetSiteInfoDTO,
  ServiceBusEnum,
  ServiceMessage,
  SiteTrackRecordDTO,
} from "../../../api-client-nswag/taxportal-client";
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Button, Link } from "@mui/material";
import { dataGridConfig } from "../../../constants";
import { SnackbarMessage, useSnackbar } from "../../../hooks";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { getExpandedRowData, getRoleData } from "../CreateSite&SetUp";
import { isEmpty, isNull } from "lodash";
import { convertUTCTimeToLocal } from "../../../util/Utils";
import { getAppName } from "../../../cui/commonComponents/AppNameToggle";
import Roles from "../../Roles/Roles";
import SiteAdminAccess from "../../SiteAdmin/SiteAdminAccess";

interface dataTypes {
  pending: boolean;
  data: SiteTrackRecordDTO[] | undefined;
  fullfilled: boolean;
}

export const statusState = atom({
  key: "statusState1",
  default: { siteId: null, status: "Active" },
});
interface statusType {
  siteId: number | null | any;
  status: string;
}
interface roleType {
  name?: string | undefined;
  RoleId?: string | undefined;
}
interface GetSiteSettingsDTO {
  id: string | number;
  setting: string;
  description: string;
  value: string;
  actionDisplayName: string;
  actionUrl: string;
}
export const SiteProcessData = (props: MasterDetailTemplateData) => {
  const [data, setData] = useState<dataTypes>({
    pending: false,
    data: [],
    fullfilled: false,
  });
  const { user, logout } = useContext(OidcIdentityContext);
  const siteId = useRecoilValue(getExpandedRowData);
  const { showSnackbar } = useSnackbar();
  const [status, setStatus] = useRecoilState<statusType>(statusState);
  const appName = useRecoilValue(getAppName);
  const roles = useRecoilValue(getRoleData);
  const [siteSettings, setSiteSettings] = useState<GetSiteSettingsDTO[]>([]);

  const processToInclude = [
    "SYNC_MDM",
    "SITE_CREATION",
    "IR_CHOICES",
    "SYNC_ASSIGNMENT",
    "DM_CHOICES",
    "SYNC_ENTITY_ISHEET",
    "PUSH_ORG_TO_ISHEET",
    "SYNC_SITE_SETTINGS",
    "CREATE_FOLDER_EXTERNAL",
    "SYNC_CEM",
    "CLIENT_LEVEL_ROLE_ASSIGN",
    "INTERNAL_USER_ASSIGNMENT",
    "SITE_ADMIN_ACCESS",
    "CREATE_EXTERNAL_USER",
    "CHECK_EXTERNAL_USER_STATUS",
    "CREATE_EXTERNAL_USER_THIRD_PARTY",
    "CHECK_THIRD_PARTY_USER_STATUS",
    "CONTROVERSY_TRACKER"
  ];

  const subProcessToInclude = [
    "L1Support",
    "TESS",
    "CLIENT_USER",
    "CS_ENGAGEMENT_TEAM",
    "CS_FUNCTIONAL_LEAD",
    "CS_PROJECT_LEAD",
  ];

  const createProcess = (type: string, subType?: string): SiteTrackRecordDTO => ({
    type,
    status: "",
    message: "",
    createdBy: "",
    created: undefined,
    lastModified: undefined,
    subType,
  });

  const ensureProcessExists = (processes: SiteTrackRecordDTO[], type: string, subTypes: string[] = []) => {
    if (!processes.some((e) => e.type === type)) {
      if (subTypes.length > 0) {
        subTypes.forEach((subType) => processes.push(createProcess(type, subType)));
      } else {
        processes.push(createProcess(type));
      }
    }
  };

  const processData = async () => {
    try {
      if (siteId !== null) {
        setData({ pending: true, data: [], fullfilled: false });
        const processDataStatus = await taxPortalClientApi.siteSetUp_SiteSetUpStatus(siteId);
        const processes = processDataStatus?.data || [];
        const filteredProcesses = filterProcesses(processes);
        ensureRequiredProcesses(filteredProcesses);
        setData({ pending: false, data: filteredProcesses, fullfilled: true });
      }
    } catch (error) {
      console.error("Error processing data:", error);
      setData({ pending: false, data: [], fullfilled: false });
    }
  };

  const filterProcesses = (processes: any) => {
    return processes.filter((item: any) => {
      const includeProcess = processToInclude.includes(item.type);
      const clientLevelRoleCondition =
        item.type === "CLIENT_LEVEL_ROLE_ASSIGN" &&
        (item.subType === null || subProcessToInclude.includes(item.subType));
      const createExternalUserCondition = item.type === "CREATE_EXTERNAL_USER" && item.subType === null;

      return includeProcess && !clientLevelRoleCondition && !createExternalUserCondition;
    });
  };

  const ensureRequiredProcesses = (processes: any) => {
    ensureProcessExists(processes, "CREATE_EXTERNAL_USER_THIRD_PARTY", ["IDM_AND_CEM", "ASSIGN_USER_TO_SITE"]);
    ensureProcessExists(processes, "CREATE_EXTERNAL_USER", ["IDM_AND_CEM", "ASSIGN_USER_TO_SITE"]);
    ensureProcessExists(processes, "CHECK_EXTERNAL_USER_STATUS", ["EXTERNAL_USER_STATUS"]);
    ensureProcessExists(processes, "CHECK_THIRD_PARTY_USER_STATUS", ["CHECK_THIRD_PARTY_USER_STATUS"]);
    ensureProcessExists(processes, "CLIENT_LEVEL_ROLE_ASSIGN", [
      "FUNCTIONAL_LEAD",
      "PROJECT_LEAD",
      "RSM_ENGAGEMENT_TEAM",
      "L2Support",
    ]);
  };

  useEffect(() => {
    if (siteId !== null) {
      processData();
    }
  }, []);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      try {
        const data = await taxPortalClientApi.taxPortal_GetHQSiteSettings();
        setSiteSettings(data);
      } catch (error) {
        console.error("Error fetching site settings:", error);
      }
    };
    fetchSiteSettings();
  }, []);

  const cellfunction = (celldata: any) => {
    const typeMap: { [key: string]: string } = {
      SYNC_MDM: "Sync MDM",
      SITE_CREATION: "Create Site from template",
      SYNC_ENTITY_ISHEET: "Populate Legal Entities",
      SYNC_CEM: "Sync CEM",
      PUSH_ORG_TO_ISHEET: "Populate Third-party Organization",
      CONTROVERSY_TRACKER: "Populate Controversy Tracker",
      IR_CHOICES: "Populate IR",
      SYNC_ASSIGNMENT: "Populate Project Status",
      SYNC_SITE_SETTINGS: "Update Site Settings",
      DM_CHOICES: "Populate Document Metadata",
      CREATE_FOLDER_EXTERNAL: "Populate Folders",
      "CLIENT_LEVEL_ROLE_ASSIGN-RSM_ENGAGEMENT_TEAM":
        "Assign Internal User -RSM Engagement Team",
      "CLIENT_LEVEL_ROLE_ASSIGN-FUNCTIONAL_LEAD":
        "Assign Internal User -Functional Lead",
      "CLIENT_LEVEL_ROLE_ASSIGN-PROJECT_LEAD":
        "Assign Internal User -Project Lead",
      "CLIENT_LEVEL_ROLE_ASSIGN-L2Support":
        "Assign Internal User -L2 Support",
      SITE_ADMIN_ACCESS: "Assign Content Admin (delegated) to site",
      INTERNAL_USER_ASSIGNMENT: "Assign Internal- Internal User Request Form.",
      "CREATE_EXTERNAL_USER-IDM_AND_CEM": "Assign External User -IDM and CEM",
      "CREATE_EXTERNAL_USER-ASSIGN_USER_TO_SITE": "Assign External User -Assign User to site",
      "CHECK_EXTERNAL_USER_STATUS-EXTERNAL_USER_STATUS": "External User -Update Status",
      "CREATE_EXTERNAL_USER_THIRD_PARTY-IDM_AND_CEM": "Assign Third Party User -IDM and CEM",
      "CREATE_EXTERNAL_USER_THIRD_PARTY-ASSIGN_USER_TO_SITE": "Assign Third Party User - Assign User to site",
      "CHECK_THIRD_PARTY_USER_STATUS-CHECK_THIRD_PARTY_USER_STATUS": "Third Party User -Update Status",
    };

    const key = celldata.data.type + (celldata.data.subType ? `-${celldata.data.subType}` : "");
    return typeMap[key] || "";
  };

  const customOrder = [
    "SITE_CREATION",
    "SYNC_MDM",
    "IR_CHOICES",
    "SYNC_ASSIGNMENT",
    "DM_CHOICES",
    "SYNC_ENTITY_ISHEET",
    "PUSH_ORG_TO_ISHEET",
    "CONTROVERSY_TRACKER",
    "SYNC_SITE_SETTINGS",
    "CREATE_FOLDER_EXTERNAL",
    "SYNC_CEM",
    "CLIENT_LEVEL_ROLE_ASSIGN",
    "SITE_ADMIN_ACCESS",
    "INTERNAL_USER_ASSIGNMENT",
    "CREATE_EXTERNAL_USER",
    "CHECK_EXTERNAL_USER_STATUS",
    "CREATE_EXTERNAL_USER_THIRD_PARTY",
    "CHECK_THIRD_PARTY_USER_STATUS",
  ];

  const sortedDataSource = data?.data?.sort((a, b) => {
    return customOrder.indexOf(a.type!) - customOrder.indexOf(b.type!);
  });

  const canRun = () => {
    return sortedDataSource?.find((e) => {
      if (e.type === "SYNC_MDM" && e.status === "SUCCESS") return true;
      else return false;
    });
  };

  const isSyncMdmInProgressOrError = () => {
    return sortedDataSource?.some((e) => {
      return e.type === "SYNC_MDM" && (e.status === "ERROR" || e.status === "INPROGRESS" || e.status === null);
    });
  };

  const retryButton = (celldata: any) => {
    const type = celldata.data.type;
    const subType = celldata.data.subType;
    const status = celldata.data.status;
    const roleNames = celldata.data.subType;
    const roleName = roleNames?.replace(/_/g, " ").toLowerCase();
    const roleId = roles?.find((role: GetAllRolesDTO) => role?.name?.toLowerCase() === roleName)?.roleId;

    const showRetry = () => {
      return type === "SITE_CREATION" && (status === null || status === "INPROGRESS" || status === "ERROR");
    };

    const showRunForProcess = () => {
      const validProcessTypes = [
        "SYNC_MDM",
        "IR_CHOICES",
        "SYNC_ASSIGNMENT",
        "DM_CHOICES",
        "SYNC_ENTITY_ISHEET",
        "PUSH_ORG_TO_ISHEET",
        "CONTROVERSY_TRACKER",
        "SYNC_SITE_SETTINGS",
        "CREATE_FOLDER_EXTERNAL",
        "SYNC_CEM",
        "CREATE_EXTERNAL_USER",
        "CHECK_EXTERNAL_USER_STATUS",
        "CLIENT_LEVEL_ROLE_ASSIGN",
        "SITE_ADMIN_ACCESS",
        "INTERNAL_USER_ASSIGNMENT",
        "CREATE_EXTERNAL_USER_THIRD_PARTY",
        "CHECK_THIRD_PARTY_USER_STATUS",
      ];
      return validProcessTypes.includes(type);
    };

    const retryProcess = async (celldata: any) => {
      const syncType = (() => {
        if (type === "SYNC_MDM") return ServiceBusEnum.SYNC_MDM;
        if (type === "SYNC_ASSIGNMENT") return ServiceBusEnum.SYNC_CEM_HQ;
        if (type === "IR_CHOICES") return ServiceBusEnum.IR_CHOICES;
        if (type === "SYNC_ENTITY_ISHEET") return ServiceBusEnum.SYNC_ENTITY_ISHEET;
        if (type === "PUSH_ORG_TO_ISHEET") return ServiceBusEnum.PUSH_ORG_TO_ISHEET;
        if (type === "CONTROVERSY_TRACKER") return ServiceBusEnum.CONTROVERSY_TRACKER;
        if (type === "SYNC_CEM") return ServiceBusEnum.SYNC_CEM;
        if (type === "SYNC_SITE_SETTINGS") return ServiceBusEnum.SYNC_SITE_SETTINGS;
        if (type === "DM_CHOICES") return ServiceBusEnum.DM_CHOICES;
        if (type === "CREATE_FOLDER_EXTERNAL") return ServiceBusEnum.EXTERNAL_FOLDER_CREATION;
        if (type === "PUSH_ORG_TO_ISHEET") return ServiceBusEnum.PUSH_ORG_TO_ISHEET;
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "RSM_ENGAGEMENT_TEAM") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "PROJECT_LEAD") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "FUNCTIONAL_LEAD") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "CLIENT_LEVEL_ROLE_ASSIGN" && subType === "L2Support") {
          return ServiceBusEnum.PROVIDE_APP_ACCESS;
        }
        if (type === "SITE_ADMIN_ACCESS") {
          return ServiceBusEnum.SITE_ADMIN_ACCESS;
        }
        if (type === "CREATE_EXTERNAL_USER" && subType === "IDM_AND_CEM") {
          return ServiceBusEnum.CREATE_EXTERNAL_USER;
        }
        if (type === "CREATE_EXTERNAL_USER" && subType === "ASSIGN_USER_TO_SITE") {
          return ServiceBusEnum.CREATE_EXTERNAL_USER;
        }
        if (type === "CHECK_EXTERNAL_USER_STATUS")
          return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        if (type === "CREATE_EXTERNAL_USER_THIRD_PARTY")
          return ServiceBusEnum.CREATE_EXTERNAL_USER_THIRD_PARTY;
        if (type === "CHECK_THIRD_PARTY_USER_STATUS")
          return ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS;
        if(type ===  "INTERNAL_USER_ASSIGNMENT")
          return ServiceBusEnum.INTERNAL_USER_ASSIGNMENT;
        return null;
      })();

      const assignmentType = (() => {
        if (subType === "ASSIGN_USER_TO_SITE") {
          return "Assign User to site";
        }
        if (subType === "IDM_AND_CEM") return "IDM and CEM";
        if (type === "CHECK_EXTERNAL_USER_STATUS") return "Check Status";
        if (type === "CHECK_THIRD_PARTY_USER_STATUS" && subType === "CHECK_THIRD_PARTY_USER_STATUS")
          return "Check Status";
      })();

      const roleType = (() => {
        if (subType === "PROJECT_LEAD") {
          return "Project Lead";
        }
        if (subType === "FUNCTIONAL_LEAD") {
          return "Functional Lead";
        }
        if (subType === "RSM_ENGAGEMENT_TEAM") {
          return "RSM Engagement Team";
        }
        if (subType ==="L2Support") {
          return "L2Support";
        }
      })();

      const isheetType = (() => {
        if (type === "CREATE_EXTERNAL_USER" && subType === "IDM_AND_CEM") return "Client User Requests";
        if (type === "CREATE_EXTERNAL_USER" && subType === "ASSIGN_USER_TO_SITE") return "Client User Requests";
        if (type === "CHECK_EXTERNAL_USER_STATUS") return "Client User Requests";

        if (type === "CREATE_EXTERNAL_USER_THIRD_PARTY" && subType === "IDM_AND_CEM")
          return "Third-party User Request Form";
        if (type === "CREATE_EXTERNAL_USER_THIRD_PARTY" && subType === "ASSIGN_USER_TO_SITE")
          return "Third-party User Request Form";
        if (type === "CHECK_THIRD_PARTY_USER_STATUS" && subType === "CHECK_THIRD_PARTY_USER_STATUS")
          return "Third-party User Request Form";
      })();

      if (syncType) {
        const ids = siteSettings.map((e: GetSiteSettingsDTO) => e.id) as number[] | undefined;

        const data: ServiceMessage = {
          siteId: props?.data?.data?.hqSiteId,
          userName: user?.profile.preferred_username,
          eventType: syncType,
          externalUserType: assignmentType,
          roleName: roleType,
          roleId: roleId,
          isheetType: isheetType,
          mdmClientId: props?.data?.data?.mdmClientId,
          clientName: props?.data?.data?.name,
          settingsIds: ids,
          appName: appName,
        };
        try {
          if (syncType === ServiceBusEnum.PROVIDE_APP_ACCESS) {
            await taxPortalClientApi.taxPortal_ProvideAppAccess([data]);
         }
         else {
           await taxPortalClientApi.taxPortal_InsertTopic([data]);
         }
          if (syncType === ServiceBusEnum.SYNC_MDM) {
            showSnackbar({ message: SnackbarMessage.SYNC_MDM });
          }
          if (syncType === ServiceBusEnum.IR_CHOICES) {
            showSnackbar({ message: SnackbarMessage.SYNC_IR });
          }
          if (syncType === ServiceBusEnum.SYNC_CEM_HQ) {
            showSnackbar({ message: SnackbarMessage.SYNC_PS });
          }
          if (syncType === ServiceBusEnum.SYNC_ENTITY_ISHEET) {
            showSnackbar({
              message: SnackbarMessage.POPULATE_LEGAL_ENTITIES,
            });
          }
          if (syncType === ServiceBusEnum.PUSH_ORG_TO_ISHEET) {
            showSnackbar({
              message: SnackbarMessage.POPULATE_THIRD_PARTY_ORG,
            });
          }
          if (syncType === ServiceBusEnum.CONTROVERSY_TRACKER) {
            showSnackbar({
              message: SnackbarMessage.POPULATE_CONTROVERSY_TRACKER,
            });
          }
          if (syncType === ServiceBusEnum.SYNC_CEM) {
            showSnackbar({ message: SnackbarMessage.RETRY_SYNC_CEM });
          }
          if (syncType === ServiceBusEnum.SYNC_SITE_SETTINGS) {
            showSnackbar({ message: SnackbarMessage.SYNC_SITE_SETTINGS_RETRY });
          }
          if (syncType === ServiceBusEnum.DM_CHOICES) {
            showSnackbar({ message: SnackbarMessage.POPULATE_DM_CHOICES });
          }
          if (syncType === ServiceBusEnum.EXTERNAL_FOLDER_CREATION) {
            showSnackbar({ message: SnackbarMessage.CREATE_FOLDER });
          }
          if (syncType === ServiceBusEnum.CREATE_EXTERNAL_USER && subType === "IDM_AND_CEM") {
            showSnackbar({ message: SnackbarMessage.IDM_AND_CEM });
          }
          if (syncType === ServiceBusEnum.CREATE_EXTERNAL_USER && subType === "ASSIGN_USER_TO_SITE") {
            showSnackbar({ message: SnackbarMessage.ASSIGN_USER_TO_SITE });
          }
          if(syncType === ServiceBusEnum.CREATE_EXTERNAL_USER_THIRD_PARTY && subType === "IDM_AND_CEM"){
            showSnackbar({message: SnackbarMessage.EX_IDM_AND_CEM})
          }
          if(syncType === ServiceBusEnum.CREATE_EXTERNAL_USER_THIRD_PARTY && subType === "ASSIGN_USER_TO_SITE"){
            showSnackbar({message: SnackbarMessage.ASSIGN_USER_TO_SITE})
          }
            showSnackbar({message: SnackbarMessage.ASSIGN_USER_TO_SITE})
          if (syncType === ServiceBusEnum.CHECK_EXTERNAL_USER_STATUS) {
            showSnackbar({ message: SnackbarMessage.CHECK_EXTERNAL_USER_STATUS });
          }
          if (syncType === ServiceBusEnum.PROVIDE_APP_ACCESS) {
            showSnackbar({ message: SnackbarMessage.PROVIDE_APP_ACCESS });
          }
          if(type ===  "INTERNAL_USER_ASSIGNMENT")
            showSnackbar({ message: SnackbarMessage.PROVIDE_APP_ACCESS});
          if(type ===  "SITE_ADMIN_ACCESS")
            showSnackbar({ message: SnackbarMessage.PROVIDE_CONTENT_ADMIN_ACCESS});
        }
         catch (error) {
          showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
      }
    };

    const retryForSynMDM = async (celldata :any) =>{
      const data: ServiceMessage = {
        siteId: props?.data?.data?.hqSiteId,
        userName: user?.profile.preferred_username,
        eventType: ServiceBusEnum.SYNC_MDM,
        mdmClientId: props?.data?.data?.mdmClientId,
        clientName : props?.data?.data?.name,
        appName: appName,
      };
      await taxPortalClientApi.taxPortal_InsertTopic([data]);
    }

    return (
      <div>
        {showRetry() ? (
          <Link
            component="button"
            variant="body2"
            onClick={retryProcess}
          >
            Run
          </Link>
        ) : showRunForProcess() ? (
          <Link
            component="button"
            variant="body2"
            style={{
              color: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? "grey" : "009cde",
              pointerEvents: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? "none" : "auto",
              opacity: isSyncMdmInProgressOrError() && type !== "SYNC_MDM" ? 0.5 : 1,
            }}
            onClick={canRun() && showRunForProcess() ? retryProcess : retryForSynMDM } 
          >
            Run
          </Link>
        ) : null}
      </div>
    );
  };

  const checkStatusAndUpdate = (celldata: any) => {
    if (!isNull(celldata) && !isEmpty(celldata)) {
      const hasError = celldata.some((e: any) => e.status === "ERROR");
      const hasSuccess = celldata.some((e: any) => e.status === "SUCCESS");
      const hasEmptyStatus = celldata.some((e: any) => e.status === null);

      if (hasEmptyStatus) {
        return "INCOMPLETE";
      }
      if (!hasError) {
        return "SUCCESS";
      }
      if (hasSuccess) {
        return "PARTIAL SUCCESS";
      }
      if (celldata.every((e: any) => e.status === "ERROR")) {
        return "FAILED";
      }
    }
    return "";
  };

  const createdDateTime = (e: any) => {
    if (!isNull(e.data.status) && !isEmpty(e.data.status)) {
      if (e.data.created === null) {
        return null;
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <div>{convertUTCTimeToLocal(e.data.created)}</div>
            </div>
          </div>
        );
      }
    } else return null;
  };
  const modifiedDateTime = (e: any) => {
    if (!isNull(e.data.status) && !isEmpty(e.data.status)) {
      if (e.data.created === null) {
        return null;
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div>
              <div>{convertUTCTimeToLocal(e.data.lastModified)}</div>
            </div>
          </div>
        );
      }
    } else return null;
  };

  useEffect(() => {
    if (sortedDataSource != null && siteId !== null) {
      const parentStatus = checkStatusAndUpdate(sortedDataSource);
      setStatus({ siteId: siteId, status: parentStatus });
    }
  }, [sortedDataSource]);

  return (
    <div>
      <DataGrid
        {...dataGridConfig}
        dataSource={sortedDataSource}
        showBorders={true}
        allowColumnResizing={true}
        showRowLines={true}
        columnAutoWidth={true}
        height={"100%"}
        noDataText={data.pending === true && data.fullfilled === false ? "Loading..." : "No data"}
      >
        <Column dataField="type" width={310} caption="process" cellRender={cellfunction} allowSorting={true} />
        <Column dataField="status" caption="Status" />
        <Column width={290} dataField="message" caption="Message" />
        <Column dataField="createdBy" caption="Initiated by" />
        <Column cellRender={createdDateTime} dataField="created" caption="Initiated date and time" />
        <Column cellRender={modifiedDateTime} dataField="lastModified" caption="Completed date and time" />
        <Column width={100} dataField="Run Process" cellRender={retryButton} />
      </DataGrid>
    </div>
  );
};
