import { ExpandMore } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import TooltipWrapper from "../toolTip/toolTip";
import {
  NavigationMenu,
  NavigationMenuListChildObject,
  NavigationMenuListObject,
  NavigationMenuSingleObject,
  NavigationProps,
} from "./NavigationBar.types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useRecoilValue } from "recoil";
import { allPermissions } from "../../../App";

export const NavigationBar: React.FunctionComponent<NavigationProps> = (props) => {
  const [menuHeight, setMenuHeight] = useState(window.innerHeight);
  const enableAccordion = props.enableAccordion || false;
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState<number>(-1);
  const permission = useRecoilValue(allPermissions);
  const initialTooltipState = props.menu.reduce((acc, _, index) => {
    acc[index] = false;
    return acc;
  }, {} as Record<number, boolean>);
  const [tooltipOpen, setTooltipOpen] = useState(initialTooltipState);

  const updateMenuHeight = () => {
    setMenuHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMenuHeight);
    return () => window.removeEventListener("resize", updateMenuHeight);
  });

  function isNavigationMenuSingleObject(object: any): object is NavigationMenuSingleObject {
    return !Object.hasOwn(object, "listObjects");
  }

  const setIsActive = (e: any) => {
    e.preventDefault();
    props.setIsNavActive(!props.isNavActive);

    if (!props.isNavActive) {
      setTooltipOpen(initialTooltipState);
    }
  };

  const handleTooltipToggle = (index: number, isOpen: boolean) => {
    if (!props.isNavActive) setTooltipOpen((prev) => ({ ...prev, [index]: isOpen }));
  };

  const renderSingleMenuObject = (navMenuObj: NavigationMenuSingleObject, index: number) => {
    // var perm = true;
    // if (appPermissions.length > 0) {
    //   for (var i = 0; i < appPermissions.length; i++) {
    //     if (navMenuObj.tooltip === appPermissions[i]) {
    //       perm = true;
    //     }
    //   }
    // }

    const key = navMenuObj.name + "_" + index;
    return (
      <li key={key}>
        <TooltipWrapper
          condition={true}
          toolTipPlacement="right"
          show={props.isNavActive}
          title={navMenuObj.name}
          key={"singleMenu_" + index}
          index={index}
          tooltipOpen={tooltipOpen}
          handleTooltipToggle={handleTooltipToggle}
        >
          <NavLink to={navMenuObj.redirectUrl || "#"} end>
            {navMenuObj.icon ? <span className="material-icons-round">{navMenuObj.icon}</span> : null}
            <span className="nav-name" dangerouslySetInnerHTML={{ __html: navMenuObj.name }}></span>
          </NavLink>
        </TooltipWrapper>
      </li>
    );
  };

  const renderMenuListObject = (navMenuListObj: NavigationMenuListObject, index: number) => {
    const key = navMenuListObj.name + "_" + index;
    return (
      <>
        {props.isNavActive ? (
          <li key={key}>
            <TooltipWrapper
              condition={true}
              toolTipPlacement="right"
              show={props.isNavActive}
              title={navMenuListObj.name}
              key={"singleMenu_" + index}
              index={index}
              tooltipOpen={tooltipOpen}
              handleTooltipToggle={handleTooltipToggle}
            >
              <span>
                <Accordion
                  className="nav-accordion"
                  defaultExpanded={index === expandedAccordionIndex}
                  disabled={!enableAccordion}
                  square={true}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    className="nav-accordion-header"
                  >
                    {navMenuListObj.icon ? <span className="material-icons-round">{navMenuListObj.icon}</span> : null}
                    <span className="nav-name" dangerouslySetInnerHTML={{ __html: navMenuListObj.name }}></span>
                  </AccordionSummary>
                  <AccordionDetails className="nav-accordion-details">
                    {navMenuListObj.listObjects && (
                      <ul key={index} className="nav-list nav-list_lvl-two" title={navMenuListObj.name}>
                        {renderSecondaryMenu(navMenuListObj.listObjects)}
                      </ul>
                    )}
                  </AccordionDetails>
                </Accordion>
              </span>
            </TooltipWrapper>
          </li>
        ) : (
          <>
            <li key={key}>
              <TooltipWrapper
                condition={true}
                toolTipPlacement="right"
                show={props.isNavActive}
                title={navMenuListObj.name}
                key={"singleMenu_" + index}
                index={index}
                tooltipOpen={tooltipOpen}
                handleTooltipToggle={handleTooltipToggle}
              >
                <NavLink to={navMenuListObj.listObjects[0].links[0].redirectUrl || "#"} end>
                  {navMenuListObj.icon ? <span className="material-icons-round">{navMenuListObj.icon}</span> : null}
                  <span className="nav-name" dangerouslySetInnerHTML={{ __html: navMenuListObj.name }}></span>
                </NavLink>
              </TooltipWrapper>
            </li>
          </>
        )}
      </>
    );
  };

  const renderSecondaryMenu = (navMenuListChildObj: NavigationMenuListChildObject[]) => {
    return navMenuListChildObj.map((childObj, index) => {
      return (
        <li>
          {/* <span>{childObj.header}</span> */}
          {childObj.links.length > 0 && (
            <ul className="nav-list nav-list_lvl-three">
              {childObj.links.map((link, listIndex) => {
                return (
                  <li key={link.name + "_" + index + "_" + listIndex}>
                    <Link to={link.redirectUrl}>
                      <span className="material-icons">{link.icon}</span>
                      <span className="nav-name">{link.name}</span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </li>
      );
    });
  };

  const renderMenu = (home: NavigationMenuSingleObject, menu: NavigationMenu[]) => {
    return (
      <>
        <ul key={"nav-list"} className="nav-list nav-list_lvl-one">
          <li title="Home" key={"home"}>
            <Link to={home.redirectUrl}>
              <span className="material-icons">{home.icon}</span>
              <span className="nav-name">{home.name}</span>
            </Link>
          </li>

          {menu.map((navMenuItem, index) => {
            if (navMenuItem.name === "Create Sites" && permission.CreateSites) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Create and Setup Sites" && permission.CreateSitesAutomate) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            // if (navMenuItem.name === "Create task" && permission.CreateTasks) {
            //   return isNavigationMenuSingleObject(navMenuItem)
            //     ? renderSingleMenuObject(navMenuItem, index)
            //     : renderMenuListObject(navMenuItem, index);
            // }
            if (navMenuItem.name === "Update Entities, Projects and Phases" && permission.Assignments) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Site Settings" && permission.SiteSettingsManager) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Reports - TP" && permission.PowerBI) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Reports - CS" && permission.CSPowerBI) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Assign Users" && permission.AssignUsers) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Third Party Organization" && permission.ThirdpartyOrganization) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Create Folders" && permission.CreateFolders) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Update Metadata Location Files" && permission.UpdateMetadataLocationFiles) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Schedule Files" && permission.ScheduleFiles) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Upgrade Sites" && permission.UpgradeSites) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Site Metrics" && permission.LeaderShip) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
            if (navMenuItem.name === "Global Tax Information" && permission.PowerBI) {
              return isNavigationMenuSingleObject(navMenuItem)
                ? renderSingleMenuObject(navMenuItem, index)
                : renderMenuListObject(navMenuItem, index);
            }
          })}
        </ul>
      </>
    );
  };

  const Nav = (home: NavigationMenuSingleObject, menu: NavigationMenu[]) => {
    return <nav>{renderMenu(home, menu)}</nav>;
  };

  return (
    <>
      {props.isNavigationBarEnabled && (
        <div id="cui-layout_nav" className={props.isNavActive ? "nav-expanded" : "nav-collapsed"}>
          <div className="cui-nav" style={{ height: menuHeight }}>
            <menu>
              <button onClick={setIsActive}>
                <span className="material-icons">menu</span>
                <span className="nav-name">Menu</span>
              </button>
            </menu>
            {Nav(props.home, props.menu)}
          </div>
        </div>
      )}
    </>
  );
};
