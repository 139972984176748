import { useEffect, useRef, useState } from "react";
import DataGrid from "devextreme-react/data-grid";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  CEMClientDTO,
  MDMLegalEntityDTO,
  SiteConfig,
  SitetemplateDTO,
} from "../../../api-client-nswag/taxportal-client";
import { isEmpty, isNull } from "lodash";
import TemplateDropDown from "./TemplateDropDown";
import CustomizeSiteName from "./CustomizeSiteName";
import CustomizeSiteNameForIS from "./CustomizeSiteNameForIS";

type DatagridRefType = DataGrid;

interface siteTemplateType {
  pending: boolean;
  fulfilled: boolean;
  siteTemplate: SitetemplateDTO[];
}


const SelectClientAndTemplateForIS = (props: any) => {
  const dataGridRef = useRef<DatagridRefType>(null);
  const [siteTemplate, setsiteTemplate] = useState<siteTemplateType>({
    pending: true,
    fulfilled: false,
    siteTemplate: [],
  });

  const [siteDates, setSiteDates] = useState<SiteConfig>();

  const [searchTimeout, setSearchTimeout] = useState(null);

  const options = siteTemplate?.siteTemplate?.map((template) => ({
    value: template.id,
    label: template.sitename,
  }));

  useEffect(() => {
    props.setSiteConfig(siteDates);
  }, [siteDates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setsiteTemplate({ pending: true, fulfilled: false, siteTemplate: [] });
        const data = await taxPortalClientApi.highQ_GetHQSiteTemplate(
          props.appName === "PERF" ? null : props.appName
        );
        setsiteTemplate({
          pending: false,
          fulfilled: true,
          siteTemplate: data,
        });
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  useEffect(() => {
    if (
      props.tab === 1 &&
      props.selectedOption !== null &&
      props.selectedOption !== undefined
    ) {
      props.setBtnContinueDisable(false);
    }
    if (
      props.tab === 1 &&
      props.selectedOption === null &&
      props.selectedOption !== undefined
    ) {
      props.setBtnContinueDisable(true);
    }
  }, [props.selectedRowsData, props.tab, props.selectedOption]);

  const handleOnChange = (selectedOption: any) => {
    props.setSelectedOption(selectedOption);
  };
  return (
    <>
      {props.tab === 1 && (
        <div style={{height:"200px"}}>
          <div
            style={{
              display: "flex",
              margin: "0 0 16px 0",
            }}
          >
            <TemplateDropDown
              options={options}
              handleOnChange={handleOnChange}
              siteTemplate={siteTemplate}
              selectedOption={props.selectedOption}
            />
          </div>
          {/* <DataGrid
            {...dataGridConfig}
            showBorders={true}
            height={250}
            onSelectionChanged={onSelectionchanged}
            dataSource={gridData?.gridData}
            noDataText={gridData.pending && gridData.gridData?.length === 0 ? "Loading..." : "No data"}
            key={"mdmMasterClientId"}
            ref={dataGridRef}
          >
            <Selection mode="single" selectAllMode={false} allowSelectAll={false} />
            <Scrolling mode="standard" />
            <LoadPanel enabled={false} />
            <Scrolling columnRenderingMode="virtual" />
            <Column
              dataField="selected"
              caption="Select"
              width={50}
              alignment="center"
              allowSorting={false}
              allowFiltering={false}
              allowReordering={false}
              cellRender={cellRender}
            />
            <Column alignment="left" dataField="mdmMasterClientId" caption={"Master Client Id"}></Column>
            <Column alignment="left" dataField="name" caption={"Master Client Name"}></Column>
          </DataGrid> */}
        </div>
      )}

      {props.tab === 2 && (
        <CustomizeSiteNameForIS
          selectedRowsData={props.selectedRowsData}
          setIsFlag={props.setIsFlag}
          setsiteNameForIS={props.setsiteNameForIS}
          siteNameForIS={props.siteNameForIS}
          setChecked={props.setChecked}
        />
      )}
    </>
  );
};

export default SelectClientAndTemplateForIS;
