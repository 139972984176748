import React, { useEffect, useState } from "react";
import { selector, useRecoilState, useRecoilValue } from "recoil";
import { getTaxYearResponse } from "../SitesWithLegalEntities";
import { format } from "date-fns";

import DateBox from "devextreme-react/date-box";
const dateLabel = { "aria-label": "Date" };

function SelectTaxYear(props: any) {
  const [taxYear, setTaxYear] = useRecoilState<any>(getTaxYearResponse);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, "yyyy");
  };

  const handleYear = (value: any) => {
    if (value != null && value != undefined) {
      const formattedYear = value !== undefined ? formatDate(value) : null;
      setTaxYear(formattedYear);
    }
  };
  return (
    <>
      <div>Instruction:</div>
      <div style={{ color: "#009cde" }}>
        {"Select the Tax Year for your client."}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          margin: "16px 0 0 0",
        }}
      >
        <label style={{ display: "flex", alignItems: "center" }}>
          Tax Year
        </label>

        <DateBox
          onValueChanged={(newValue) => handleYear(newValue.value)}
          defaultValue={undefined}
          inputAttr={dateLabel}
          type="date"
          pickerType="calendar"
          displayFormat="yyyy"
          calendarOptions={{
            zoomLevel: "decade",
            minZoomLevel: "decade",
            maxZoomLevel: "decade",
          }}
        />
      </div>
    </>
  );
}

export default SelectTaxYear;
