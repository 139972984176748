import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from "@mui/material";

import { CheckboxProps } from "./CheckboxHQ.types";
import "./CheckboxHQ.scss";
import { ServiceBusEnum } from "../../../api-client-nswag/taxportal-client";

export default function CheckboxHQ({ title, options, setCheckboxValues }: CheckboxProps) {
  const onChange = (id: ServiceBusEnum) =>
    setCheckboxValues((checkboxes) =>
      checkboxes.map((item) => (item.id === id ? { ...item, checked: !item.checked } : item))
    );

  return (
    <FormControl component="fieldset">
      <FormLabel component="h2" className="hq-title">
        {title}
      </FormLabel>
      <FormGroup aria-label="position" row>
        {options.map(({ id, text, checked }) => {
          return (
            <FormControlLabel
              key={id}
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => onChange(id)}
                  name={text}
                  color="default"
                  size="small"
                  className="hq__checkbox"
                />
              }
              label={text}
            />
          );
        })}
      </FormGroup>
    </FormControl>
  );
}
