import { useContext, useEffect, useState } from "react";
import { dataGridConfig } from "../../constants";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { ServiceBusEnum, ServiceMessage, SiteReport } from "../../api-client-nswag/taxportal-client";
import { convertUTCTimeToLocal } from "../../util/Utils";
import { useSnackbar } from "../../hooks";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { snackbarState } from "../../cui/commonComponents/CustomSnackbar";
import { useRecoilState, useSetRecoilState } from "recoil";
import FileSaver from "file-saver";
import { flagUsageMetrics } from "../../App";

const requiredReports = [
  {
    reportType: "DOCUMENTS_PER_SITE",
    reportLocation: "NumberOfUploadedDocumentsPerSite.xlsx",
  },
  {
    reportType: "DOCUMENTS_COPIED_TO_IMANAGE",
    reportLocation: "DocumentCountCopiedToiManage.xlsx",
  },
  {
    reportType: "WORKSPACE_ASSOCIATED_PER_SITE",
    reportLocation: "WorkSpaceAssociatedPerSite.xlsx",
  },
  {
    reportType: "LEGALENTITES_PER_SITE",
    reportLocation: "LegalEntitiesPerSite.xlsx",
  },
];

export interface SiteReportTypes {
  pending: boolean;
  data: SiteReport[];
}

const SiteMetricsRunFunctions = () => {
  const [data, setData] = useState<SiteReportTypes>({
    pending: false,
    data: [],
  });
  const { user } = useContext(OidcIdentityContext);
  const setSnackbar = useSetRecoilState(snackbarState);
  const [usageMetricsFlag, setUsageMetricsFlag] = useRecoilState(flagUsageMetrics);

  const fetchData = async () => {
    try {
      setData({
        pending: true,
        data: [],
      });
      const res = await taxPortalClientApi.usageMetrics_UsageMetrics();
      const updatedReports = requiredReports.filter(
        (report) => !res.some((item) => item.reportType === report.reportType)
      );
      setData({
        pending: false,
        data: [...res, ...updatedReports],
      });
    } catch (error) {
      console.error("Error fetching usage metrics:", error);
    }
  };
  useEffect(() => {
    if (usageMetricsFlag === false) {
      fetchData();
    }
  }, []);
  useEffect(() => {
    if (usageMetricsFlag === true) {
      fetchData();
      setUsageMetricsFlag(false);
    }
  }, [usageMetricsFlag]);

  const handleDownload = async (reportLocation: string, lastModified: string | null) => {
    try {
      if (lastModified === null || lastModified === undefined) {
        setSnackbar({
          open: true,
          message: "Please run the functionality first",
          severity: "info",
        });
        return;
      }
      await taxPortalClientApi.usageMetrics_DownloadUsageMetricFile(reportLocation).then((res: any) => {
        FileSaver.saveAs(res);
      });
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const cellRenderValue = (cellData: any) => (
    <>
      <span
        onClick={() => handleDownload(cellData.data.reportLocation, cellData.data.lastModified)}
        style={{
          color: "#009cde",
          textDecoration: "underline",
          cursor: "pointer",
          margin: "0 5px 0 0",
        }}
      >
        Download
      </span>
      <span
        onClick={() => onRunClick(cellData)}
        style={{
          color: "#009cde",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Run
      </span>
    </>
  );

  const cellRenderDate = (cellData: any) => {
    return cellData.data.lastModified ? convertUTCTimeToLocal(cellData.data.lastModified) : null;
  };

  const onRunClick = async (cellData: any) => {
    var type = "";
    if (cellData.data.reportType === "DOCUMENTS_PER_SITE") {
      type = "Count of documents uploades per active site";
    }
    if (cellData.data.reportType === "DOCUMENTS_COPIED_TO_IMANAGE") {
      type = "Count of documents copied to iManage";
    }
    if (cellData.data.reportType === "WORKSPACE_ASSOCIATED_PER_SITE") {
      type = "Workspace associated per site";
    }
    if (cellData.data.reportType === "LEGALENTITES_PER_SITE") {
      type = "Legal entities per site";
    }
    setSnackbar({
      open: true,
      message: `${type} in progress`,
      severity: "info",
    });

    const data: ServiceMessage = {
      userName: user?.profile.preferred_username,
      usageMetricsType: cellData.data.reportType,
      eventType: ServiceBusEnum.USAGE_METRICS_REPORT,
    };
    try {
      await taxPortalClientApi.taxPortal_InsertTopic([data]);
    } catch (error) {
      console.error("Error running the report:", error);
    }
  };

  const cellRenderReportType = (cellData: any) => {
    if (cellData.data.reportType === "DOCUMENTS_PER_SITE") {
      return "Count of documents uploades per active site";
    }
    if (cellData.data.reportType === "DOCUMENTS_COPIED_TO_IMANAGE") {
      return "Count of documents copied to iManage";
    }
    if (cellData.data.reportType === "WORKSPACE_ASSOCIATED_PER_SITE") {
      return "Workspace associated per site";
    }
    if (cellData.data.reportType === "LEGALENTITES_PER_SITE") {
      return "Legal entities per site";
    }
    return cellData.data.reportType;
  };

  return (
    <div>
      <DataGrid
        width={"100%"}
        noDataText={data.pending ? "loading..." : "No data"}
        {...dataGridConfig}
        dataSource={data.data}
      >
        <Column alignment="left" cellRender={cellRenderReportType} dataField="reportType" caption={""} />
        <Column alignment="left" cellRender={cellRenderDate} dataField="lastModified" caption={"Date Of Report"} />
        <Column alignment="left" dataField="action" cellRender={cellRenderValue} caption={"Action"} />
        <Column alignment="left" dataField="reportStatus" caption={"Status"} />
      </DataGrid>
    </div>
  );
};

export default SiteMetricsRunFunctions;
