import { useEffect, useRef, useState } from "react";
import DataGrid, { Column, Selection, Scrolling, LoadPanel } from "devextreme-react/data-grid";
import { taxPortalClientApi } from "../../../api-client-nswag/taxportal-client-runtime";
import {
  CEMClientDTO,
  MDMLegalEntityDTO,
  SiteConfig,
  SitetemplateDTO,
} from "../../../api-client-nswag/taxportal-client";
import { dataGridConfig } from "../../../constants";
import { isEmpty, isNull } from "lodash";
import TemplateDropDown from "./TemplateDropDown";
import LegalEntitesPermission from "./LegalEntitesPermission";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import SelectLegalEntities from "./SelectLegalEntities";
import CustomizeSiteName from "./CustomizeSiteName";
import { CheckBox } from "devextreme-react/check-box";
import SelectDates from "./SelectDates";
import SelectTaxYear from "./SelectTaxYear";

type DatagridRefType = DataGrid;

interface siteTemplateType {
  pending: boolean;
  fulfilled: boolean;
  siteTemplate: SitetemplateDTO[];
}
interface gridDataType {
  pending: boolean;
  fulfilled: boolean;
  gridData: CEMClientDTO[];
}
interface gridLegalEntityType {
  pending: boolean;
  fulfilled: boolean;
  gridLegalData: MDMLegalEntityDTO[];
}

interface selectedRowDataTypes {
  mdmClientId: number;
  mdmMasterClientId: number;
  name: string;
  countryCode: string;
  is7216ConsentReceived: boolean;
  year: number;
}
export interface PermissionDataType {
  mdmClientId: number;
  clientId: number;
  clientName: string;
  hqSiteId: number;
  hqClientName: string;
  suppressFolderCreation: boolean;
  suppressDropdown: boolean;
  suppressProjectStatus: boolean;
  userName: string;
}

const SelectClientAndTemplate = (props: any) => {
  const dataGridRef = useRef<DatagridRefType>(null);
  const [siteTemplate, setsiteTemplate] = useState<siteTemplateType>({
    pending: true,
    fulfilled: false,
    siteTemplate: [],
  });
  const [dataPermission, setDataPermission] = useState<PermissionDataType[]>([
    {
      mdmClientId: 0,
      clientId: 0,
      clientName: "",
      hqSiteId: 0,
      hqClientName: "",
      suppressFolderCreation: false,
      suppressDropdown: false,
      suppressProjectStatus: false,
      userName: ""
    },
  ]);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [siteDates, setSiteDates] = useState<SiteConfig>();
  const [selectedLegalEntityData, setSelectedLegalEntityData] = useState<any[]>([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [gridData, setGridData] = useState<gridDataType>({
    pending: false,
    fulfilled: false,
    gridData: [],
  });
  const [legalData, setLegalData] = useState<gridLegalEntityType>({
    pending: false,
    fulfilled: false,
    gridLegalData: [],
  });

  const [inputText, setInputText] = useState("");
  const options = siteTemplate?.siteTemplate?.map((template) => ({
    value: template.id,
    label: template.sitename,
  }));

  useEffect(() => {
    props.setSiteConfig(siteDates);
  }, [siteDates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setsiteTemplate({ pending: true, fulfilled: false, siteTemplate: [] });
        const data = await taxPortalClientApi.highQ_GetHQSiteTemplate(props.appName === "PERF" ? null : props.appName);
        setsiteTemplate({
          pending: false,
          fulfilled: true,
          siteTemplate: data,
        });
      } catch (error) {
        console.log(error, "error");
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);
  useEffect(() => {
    if (inputText?.length === 0) {
      setSelectedRowKey(null);
      dataGridRef?.current?.instance.clearSelection();
      props.setSelectedRowsData(null);
      props.setBtnContinueDisable(true);
    }
  }, [inputText]);

  useEffect(() => {
    if (
      props.tab === 1 &&
      props.selectedRowsData !== null &&
      props.selectedOption !== null &&
      props.selectedRowsData !== undefined &&
      props.selectedOption !== undefined &&
      gridData?.gridData?.length > 0 &&
      inputText?.length > 0
    ) {
      props.setBtnContinueDisable(false);
    }
    if (
      props.tab === 1 &&
      props.selectedRowsData === null &&
      props.selectedOption === null &&
      props.selectedRowsData !== undefined &&
      props.selectedOption !== undefined &&
      gridData?.gridData?.length === 0 &&
      inputText?.length === 0
    ) {
      props.setBtnContinueDisable(true);
    }
  }, [props.selectedRowsData, props.tab, props.selectedOption, inputText?.length]);

  const handleInputChange = (event: any) => {
    dataGridRef.current!.instance.clearSelection();
    const inputValue = event.target.value;
    setInputText(inputValue);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newSearchTimeout: any = setTimeout(() => {
      fetchData(inputValue);
    }, 500);
    setSearchTimeout(newSearchTimeout);
  };
  const fetchData = async (search: string) => {
    setGridData({ pending: true, fulfilled: false, gridData: [] });
    if (!isNull(search) && !isEmpty(search)) {
      const data = await taxPortalClientApi.cEM_CEMSearchClients(search);
      setGridData({ pending: false, fulfilled: true, gridData: data });
    } else {
      setGridData({ pending: false, fulfilled: false, gridData: [] });
    }
  };
  const onSelectionchanged = (e: any) => {
    props.setSelectedRowsData(e.selectedRowsData[0]);
    const selectedKey = e.selectedRowsData?.length > 0 ? e.selectedRowsData[0].mdmMasterClientId : null;
    setSelectedRowKey(selectedKey);
  };
  const handleOnChange = (selectedOption: any) => {
    props.setSelectedOption(selectedOption);
  };

  const cellRender = (e: any) => {
    return (
      <input
        type="checkbox"
        checked={
          selectedRowKey === e.data.mdmMasterClientId &&
          props.selectedRowsData !== null &&
          props.selectedRowsData !== undefined
            ? true
            : false
        }
      />
    );
  };
  useEffect(() => {
    props.setDataPermissionFinal(dataPermission);
  }, [dataPermission]);
  return (
    <>
      {props.tab === 1 && (
        <>
          <div
            style={{
              display: "flex",
              margin: "0 0 16px 0",
              justifyContent: "space-between",
            }}
          >
            <TemplateDropDown
              options={options}
              handleOnChange={handleOnChange}
              siteTemplate={siteTemplate}
              selectedOption={props.selectedOption}
            />
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{ width: "30%" }}
              placeholder="Search..."
              onChange={handleInputChange}
              value={inputText}
              InputProps={{
                startAdornment: (
                  <div style={{ position: "relative", marginRight: "18px" }}>
                    <SearchIcon
                      style={{
                        position: "absolute",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "grey",
                      }}
                    />
                  </div>
                ),
              }}
            />
          </div>
          <DataGrid
            {...dataGridConfig}
            showBorders={true}
            height={250}
            onSelectionChanged={onSelectionchanged}
            dataSource={gridData?.gridData}
            noDataText={gridData.pending && gridData.gridData?.length === 0 ? "Loading..." : "No data"}
            key={"mdmMasterClientId"}
            ref={dataGridRef}
          >
            <Selection mode="single" selectAllMode={false} allowSelectAll={false} />
            <Scrolling mode="standard" />
            <LoadPanel enabled={false} />
            <Scrolling columnRenderingMode="virtual" />
            <Column
              dataField="selected"
              caption="Select"
              width={50}
              alignment="center"
              allowSorting={false}
              allowFiltering={false}
              allowReordering={false}
              cellRender={cellRender}
            />
            <Column alignment="left" dataField="mdmMasterClientId" caption={"Master Client Id"}></Column>
            <Column alignment="left" dataField="name" caption={"Master Client Name"}></Column>
          </DataGrid>
        </>
      )}
      {props.tab === 2 && (
        <SelectLegalEntities
          selectedRowsData={props.selectedRowsData}
          setLegalData={setLegalData}
          legalData={legalData}
          setBtnContinueDisable={props.setBtnContinueDisable}
          selectedLegalEntityData={selectedLegalEntityData}
          setSelectedLegalEntityData={setSelectedLegalEntityData}
          setSelectionMode={props.setSelectionMode}
          selectionMode={props.selectionMode}
        />
      )}
      {props.tab === 3 && (
        <LegalEntitesPermission
          setDataPermission={setDataPermission}
          dataPermission={dataPermission}
          selectedLegalEntityData={selectedLegalEntityData}
          selectedRowsData={props.selectedRowsData}
        />
      )}

      {props.tab === 4 && props.appName === "CS" && (
        <SelectDates selectedRowsData={props.selectedRowsData} siteDates={siteDates} setSiteDates={setSiteDates} />
      )} 

      {props.tab === 4 && props.appName === "TP" && (
        <SelectTaxYear />
      )} 

      {props.tab === 5 && (
        <CustomizeSiteName
          selectedRowsData={props.selectedRowsData}
          dataPermission={dataPermission}
          setDataPermission={setDataPermission}
          setIsFlag={props.setIsFlag}
          setChecked={props.setChecked}
        />
      )}
    </>
  );
};

export default SelectClientAndTemplate;
