import { useEffect, useRef, useState } from "react";
import { DataGrid, Column, Scrolling, Selection } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";

import { dataGridConfig } from "../../../../constants";

import WarningIcon from "@mui/icons-material/Warning";
import {
  changesSelected,
  detailsGridData,
  selectChangesProps,
  SelectedDetailsData,
  constants,
} from "../UpgradeSitesGrids.types";
import { areObjectsEqual, disableCheckbox, disableCheckboxes, selectParent } from "./actions";

type DatagridRefType = DataGrid;

const initialStateData = { pending: true, changes: [] };
const { ALERT_MESSAGE, STATUSES } = constants;
const { SUCCESS } = STATUSES;

export const GridSelectChangesData = ({
  selectedChangesDetails,
  selectedDetails,
  setSelectedDetails,
  parentComponent,
}: selectChangesProps) => {
  const [data, setData] = useState<changesSelected>(initialStateData);
  const [selectedData, setSelectedData] = useState<String[]>([]);
  const reviewDetailsGridRef = useRef<DatagridRefType>(null);
  const [keysToDisable, setKeysToDisable] = useState<string[]>([]);

  const { comparisonId: parentKey, detailSelected, upgradeWarning, status } = selectedChangesDetails;
  const { changeId, change, details } = detailSelected!;

  const fetchData = async () => {
    try {
      setData(initialStateData);
      const dataDetails = formatToObjectsArray(details as string[]);
      setData({ pending: false, changes: dataDetails });
      setReviewDetailsView(details as string[]);
    } catch (error) {}
  };

  const setReviewDetailsView = (dataDetails: string[]) => {
    if (status === SUCCESS) {
      setSelectedData([]);
      setKeysToDisable(dataDetails);
    } else {
      setSelectedData(setSelectedDataDetails(dataDetails));
    }
  };

  const setSelectedDataDetails = (dataDetails: string[]) => {
    const selected = !selectedDetails!.length ? dataDetails : getSelected();
    return selected.length ? selected : dataDetails;
  };

  const formatToObjectsArray = (data: string[]): detailsGridData[] => data.map((detail) => ({ name: detail }));

  const onSelectionChanged = ({ currentDeselectedRowKeys, selectedRowKeys }: SelectionChangedEvent) => {
    setSelectedData(selectedRowKeys);
    if (!selectedRowKeys.length) {
      if (countDetails(currentDeselectedRowKeys) < 1) {
        clearAllDetails();
        selectParent(parentComponent!, parentKey);
        return;
      }
    } else {
      selectParent(parentComponent!, parentKey, true);
    }
    const newDetails = { comparisonId: parentKey, changeId, details: selectedRowKeys };
    if (!doesDetailIsAlreadySet(newDetails, selectedDetails!)) {
      updateDetailsData(newDetails);
    }
  };

  const countDetails = (currentDeselectedRowKeys: detailsGridData[]): number => {
    const selDetails = selectedDetails!.filter((detail) => detail.comparisonId === parentKey);
    return selDetails!.reduce((total, item) => total + item.details.length, 0) - currentDeselectedRowKeys.length;
  };

  const doesDetailIsAlreadySet = (obj: SelectedDetailsData, arr: SelectedDetailsData[]): boolean => {
    return arr.some((item) => areObjectsEqual(item, obj));
  };

  const updateDetailsData = (newDetails: SelectedDetailsData) => {
    setSelectedDetails((prevDetails) => {
      const newDetailsData = [...prevDetails];
      const foundIndex = newDetailsData.findIndex(
        (detail) => `${detail.comparisonId}${detail.changeId}` === `${parentKey}${changeId}`
      );
      if (foundIndex !== -1) {
        return newDetailsData.map((detail, index) => (index === foundIndex ? newDetails : detail));
      } else {
        return [...newDetailsData, newDetails];
      }
    });
  };

  const clearAllDetails = () => {
    setSelectedDetails((prevDetails) => {
      const newDetailsData = [...prevDetails];
      return newDetailsData.filter((detail) => detail.comparisonId !== parentKey);
    });
  };

  const getSelected = () => {
    const foundDetail = selectedDetails!.find((detail) => detail.changeId === changeId);
    return foundDetail ? foundDetail.details : [];
  };

  const renderDetails = ({
    row: {
      data: { name },
    },
  }: any) => {
    if (upgradeWarning.includes(name)) {
      return <span className="alert-text">{name}</span>;
    }
    return <span>{name}</span>;
  };

  const onContentReady = () => {
    if (keysToDisable.length) {
      const component = reviewDetailsGridRef.current!.instance!;
      disableCheckboxes(component, keysToDisable);
      disableHeaderCheckbox(component);
    }
  };

  const disableHeaderCheckbox = (gridInstance: any) => {
    const headerView = gridInstance.getView("columnHeadersView");
    const cellElement = headerView.getCellElement(0, 0);
    disableCheckbox(cellElement);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <DataGrid
        id="grid-review-changes"
        ref={reviewDetailsGridRef}
        keyExpr="name"
        {...dataGridConfig}
        dataSource={data.changes}
        noDataText={data.pending ? "Loading..." : "No data"}
        onSelectionChanged={onSelectionChanged}
        onContentReady={onContentReady}
        selectedRowKeys={selectedData}
        wordWrapEnabled
      >
        <Selection mode="multiple" selectAllMode={"page"} showCheckBoxesMode={"always"} />
        <Scrolling mode="standard" />
        <Scrolling columnRenderingMode="virtual" />
        <Column dataField="name" caption={change} cellRender={renderDetails} minWidth={70} alignment="left" />
      </DataGrid>

      <div className="alert-msg-modal alert-text">
        <WarningIcon />
        {ALERT_MESSAGE}
      </div>
    </>
  );
};
