import { useCallback, useContext, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import CheckboxHQ from "../../cui/components/CheckboxHQ/CheckboxHQ";
import { OidcIdentityContext } from "@rsmus/react-auth";
import { Button } from "@mui/material";
import GridRollover from "../../cui/components/GridRollOver/GridRollOver";
import { ModalTaxPortal } from "../../cui/components/ModalTaxPortal/ModalTaxPortal";
import { ModalGridRolloverFiles } from "../../cui/components/ModalGridRolloverFiles/ModalGridRolloverFiles";
import { ModalRolloverStatusDetails } from "../../cui/components/ModalRolloverStatusDetails/ModalRolloverStatusDetails";
import { useSnackbar } from "../../hooks";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import useRolloverRequest from "../../hooks/useRolloverRequest";

import { refreshFlagAtom } from "../../constants";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { ServiceBusEnum, ServiceMessage } from "../../api-client-nswag/taxportal-client";
import { CheckboxType } from "../../cui/components/CheckboxHQ/CheckboxHQ.types";
import { NOTIFICATION_MESSAGES, ROLLOVER_OPTIONS, SERVICE_OPTIONS, PAGE_CAPTIONS } from "./Rollovers.constants";
import "./Rollovers.scss";

const { MODAL_TITLE, ROLLOVER_PAGE_TITLE } = PAGE_CAPTIONS;
const { SCHEDULE, INSER_TOPIC, CANCEL_SCHEDULE } = SERVICE_OPTIONS;
const { INFORMATION_REQUEST, FILES, BOTH } = ROLLOVER_OPTIONS;
const { OPENING_MESSAGE, INFORMATION_REQUEST_MSG, FILES_MSG_1, FILES_MSG_2, CLOSING_MESSAGE, DEFAULT_SCHEDULE_DAYS } =
  NOTIFICATION_MESSAGES;
const initialStateCheckbox = [
  { id: ServiceBusEnum.IR_ROLLOVER, text: INFORMATION_REQUEST, checked: false },
  { id: ServiceBusEnum.FILES_ROLLOVER, text: FILES, checked: false },
];

export default function Rollovers() {
  const { user } = useContext(OidcIdentityContext);
  const refreshFlag = useRecoilValue(refreshFlagAtom);
  const [checkboxValues, setCheckboxValues] = useState<CheckboxType[]>(initialStateCheckbox);
  var siteID = sessionStorage.getItem("siteId") as string;
  const { data, fetchData, clientID: mdmClientId, scheduleDays } = useRolloverRequest(parseInt(siteID));
  const [isDisplayModal, setIsDisplayModal] = useState<boolean>(false);
  const [isDisplayRolloverFilesModal, setIsDisplayRolloverFilesModal] = useState<boolean>(false);
  const [isDisplayStatusDetailsModal, setIsDisplayStatusDetailsModal] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<number>();
  const [selectedDetailsId, setSelectedDetailsId] = useState<number>();
  const [selection, setSelection] = useState<string>();
  const { showSnackbar } = useSnackbar();

  const optionSelected = checkboxValues.some((value) => value.checked === true);

  const handleOpenModal = () => setIsDisplayModal(true);
  const handleCloseModal = () => setIsDisplayModal(false);

  const handleOpenRolloverFilesModal = (e: any) => {
    setSelectedFileId(e.row.data.id);
    setIsDisplayStatusDetailsModal(false);
    setIsDisplayRolloverFilesModal(true);
  };

  const handleCloseRolloverFilesModal = () => {
    setSelectedFileId(0);
    setIsDisplayRolloverFilesModal(false);
  };

  const handleOpenStatusDetailsModal = (e: any) => {
    setSelectedDetailsId(e.row.data.id);
    setIsDisplayRolloverFilesModal(false);
    setIsDisplayStatusDetailsModal(true);
  };

  const handleCloseStatusDetailsModal = () => {
    setSelectedDetailsId(0);
    setIsDisplayStatusDetailsModal(false);
  };

  const unSelectAll = () => setCheckboxValues((checkboxes) => checkboxes.map((item) => ({ ...item, checked: false })));

  const getSelectedOptionsObj = (option?: string) =>
    checkboxValues.filter((item) => (option ? item.text === option : item.checked === true));

  const requestRollover = useCallback(
    (selected?: string, processTrackId?: number) => {
      handleCloseModal();
      const rolloverSelected = getSelectedOptionsObj(selected);
      const informatioRequestData = rolloverSelected.filter((selected) => selected.text === INFORMATION_REQUEST);

      let data: ServiceMessage[] = [];
      if (informatioRequestData.length) {
        data = getDataToSend(informatioRequestData);
        if (rolloverSelected.length > 1) sendSchedule(rolloverSelected);
      } else if (selected) {
        data = getDataToSend(rolloverSelected, processTrackId);
      } else {
        sendSchedule(rolloverSelected);
        return;
      }
      showSnackbar({ message: SnackbarMessage.ROLLOVER_PROGRESS });
      if (data.length) callService(INSER_TOPIC, { data });
    },
    [checkboxValues]
  );

  const requestChecked = () => {
    requestRollover();
  };

  const sendSchedule = (rolloverSelected: CheckboxType[]) => {
    const filesData = rolloverSelected.filter((selected) => selected.text === FILES);
    const dataToSend = getDataToSend(filesData);
    showSnackbar({ message: SnackbarMessage.SCHEDULE_ROLLOVER });
    callService(SCHEDULE, { siteId: parseInt(siteID), data: dataToSend });
  };

  const cancelRollover = useCallback((rolloverId: number) => {
    showSnackbar({ message: SnackbarMessage.CANCEL_SCHEDULE });
    callService(CANCEL_SCHEDULE, { siteId: parseInt(siteID), rolloverId });
  }, []);

  const callService = async (option: string, params: any) => {
    try {
      const { siteId, rolloverId, data } = params;
      if (option === SCHEDULE) await taxPortalClientApi.taxPortal_ScheduleRollover(siteId, data);
      if (option === INSER_TOPIC) await taxPortalClientApi.taxPortal_InsertTopic(data);
      if (option === CANCEL_SCHEDULE) await taxPortalClientApi.taxPortal_CancelScheduledRollover(siteId, rolloverId);
      if (option !== INSER_TOPIC) fetchData();
      unSelectAll();
    } catch (err: any) {
      const { status, response } = err;
      if (status === 409) {
        showSnackbar({ message: response, severity: "error" });
      } else {
        showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
      }
    } finally {
      handleCloseModal();
    }
  };

  const getDataToSend = (rolloverSelected: CheckboxType[], processTrackId?: number) => {
    return rolloverSelected.map(
      ({ id }): ServiceMessage => ({
        eventType: id,
        mdmClientId,
        siteId: parseInt(siteID),
        userName: user?.profile.preferred_username,
        processTrackId,
      })
    );
  };

  useEffect(() => {
    const optSelected = getSelectedOptionsObj().map((opt) => opt.text);
    if (optSelected.length > 1) {
      setSelection(BOTH);
    } else {
      optSelected.map((option) => {
        if (option === INFORMATION_REQUEST) {
          setSelection(INFORMATION_REQUEST);
        } else {
          setSelection(FILES);
        }
      });
    }
  }, [isDisplayModal]);

  useEffect(() => {
    fetchData();
  }, [siteID, refreshFlag]);

  return (
    <section className="hq-page">
      <div style={{ marginLeft: "20px", marginRight: "20px" }}>
        <header className="hq-page__rollover-header">
          <CheckboxHQ title={ROLLOVER_PAGE_TITLE} options={checkboxValues} setCheckboxValues={setCheckboxValues} />
          <Button onClick={handleOpenModal} variant="contained" className="hq_button" disabled={!optionSelected}>
            Go
          </Button>
        </header>
        <GridRollover
          data={data}
          handleOpenModal={handleOpenRolloverFilesModal}
          handleOpenStatusDetailsModal={handleOpenStatusDetailsModal}
          refreshFunction={fetchData}
          requestRollover={requestRollover}
          cancelRollover={cancelRollover}
        />
      </div>
      <ModalTaxPortal
        title={MODAL_TITLE}
        btnOpenText="Continue"
        maxWidth="xs"
        isDisplayModal={isDisplayModal}
        handleContinue={requestChecked}
        handleClose={handleCloseModal}
      >
        <div className="rollover-msg">
          <p>
            {OPENING_MESSAGE}
            {(selection === INFORMATION_REQUEST || selection === BOTH) && <>{INFORMATION_REQUEST_MSG}</>}
            {(selection === FILES || selection === BOTH) && (
              <>
                {FILES_MSG_1.replace("%days%", String(scheduleDays))} {FILES_MSG_2}
              </>
            )}
          </p>
          <p>{CLOSING_MESSAGE}</p>
        </div>
      </ModalTaxPortal>
      <ModalGridRolloverFiles
        fileId={selectedFileId!}
        handleClose={handleCloseRolloverFilesModal}
        isDisplayModal={isDisplayRolloverFilesModal}
      />
      <ModalRolloverStatusDetails
        rolloverId={selectedDetailsId!}
        handleClose={handleCloseStatusDetailsModal}
        isDisplayModal={isDisplayStatusDetailsModal}
      />
    </section>
  );
}
