import { atom } from "recoil";

export const dataGridConfig = {
  renderAsync: false,
  showBorders: true,
  repaintChangesOnly: true,
  hoverStateEnabled: true,
  columnAutoWidth: false,
  showRowLines: false,
  showColumnLines: true,
  allowColumnResizing: true,
  columnMinWidth: 100,
};

export const permissionFlag = {
  permission: true,
};

export const defaultPageSize = 20;

export const signalRMethods = {
  info: "info",
  error: "error",
  success: "success",
  ir_success: "irReport",
  ps_success: "psReport",
};

export const refreshFlagAtom = atom({
  key: "refreshFlag",
  default: false,
});

export const upgradeSitesResponseAtom = atom({
  key: "upgradeSitesResponse",
  default: "",
});
