import { atom, useRecoilState } from "recoil";

export const getAppNameSiteMetric = atom({
  key: "getAppNameSiteMetric",
  default: "Sites",
});

const AppNameToggleForSiteMetric = () => {
  const [appName, setAppName] = useRecoilState<any>(getAppNameSiteMetric);

  const handleAppNameClick = (name: string | null) => {
    setAppName(name);
  };

  const buttonStyle = {
    cursor: "pointer",
    padding: "12px 24px",
    transition: "color 0.3s, transform 0.3s, box-shadow 0.3s",
    marginLeft: "20px",
    color: "#333",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    border: "none",
    backgroundColor: "transparent",
    position: "relative",
    overflow: "hidden",
    zIndex: 1,
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
  };

  const activeButtonStyle: any = {
    ...buttonStyle,
    color: "#009cde",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  };

  const activeUnderlineStyleOther: any = {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#009cde",
    transform: appName === "Other" ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s",
    zIndex: -1,
  };
  const activeUnderlineStyleSites: any = {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: "#009cde",
    transform: appName === "Sites" ? "scaleX(1)" : "scaleX(0)",
    transformOrigin: "left",
    transition: "transform 0.3s",
    zIndex: -1,
  };

  return (
    <div>
      <button
        onClick={() => {
          handleAppNameClick("Sites");
        }}
        style={appName === "Sites" ? activeButtonStyle : buttonStyle}
      >
        Sites
        <div style={activeUnderlineStyleSites}></div>
      </button>
      <button
        onClick={() => {
          handleAppNameClick("Other");
        }}
        style={appName === "Other" ? activeButtonStyle : buttonStyle}
      >
        Other
        <div style={activeUnderlineStyleOther}></div>
      </button>

      {/* )} */}
    </div>
  );
};

export default AppNameToggleForSiteMetric;
