import { ModalReviewChangesProps, constants } from "./UpgradeSitesGrids.types";
import { Modal } from "../Modal/Modal";
import { ChangesCard } from "./atoms/ChangesCard";

import "./UpgradeSites.scss";

const { MODAL_TITLE } = constants;

export const ModalReviewChanges = ({
  selectedChangesDetails,
  isDisplayModal,
  handleClose,
  isReviewOnly,
  selectedDetails,
  setSelectedDetails,
  parentComponent,
}: ModalReviewChangesProps) => {
  const styles = { position: "right", margin: "0", height: "100%", maxHeight: "100%" };

  return (
    <Modal
      title={MODAL_TITLE}
      btnCloseText="Close"
      handleClose={handleClose}
      btnCloseTop={true}
      maxWidth="xs"
      isDisplayModal={isDisplayModal}
      styles={styles}
    >
      <div>
        <ChangesCard
          selectedChangesDetails={selectedChangesDetails}
          isReviewOnly={isReviewOnly}
          selectedDetails={selectedDetails}
          setSelectedDetails={setSelectedDetails}
          parentComponent={parentComponent}
        />
      </div>
    </Modal>
  );
};
